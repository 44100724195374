<template>
	<div>
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar ..."
								trim
							/>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refInvitationListTable"
				class="position-relative table-tenders"
				:style="totalInvitations >= 1 && totalInvitations <= 2 ? 'height: 200px' : 'height: inherit'"
				:items="fetchInvitations"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(answer)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(sent_date)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(stage_end_date)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(bidder_name)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar
								size="32"
								:src="null"
								:text="avatarText(data.item.bidder_name)"
								variant="light-success"
							/>
						</template>
						<span class="font-weight-bold d-block text-nowrap">{{ data.item.bidder_name }}</span>
					</b-media>
				</template>

				<template #cell(tender_title)="data">
					<span class="font-weight-bold d-block text-nowrap">{{ data.item.tender_title }}</span>
				</template>

				<template #cell(tender_code)="data">
					<div class="text-nowrap">{{ data.item.tender_code }}</div>
				</template>

				<template #cell(answer)="data">
					<div class="text-nowrap">
						<b-badge
							:variant="`light-${data.item.answer == 0 ? 'danger' : data.item.answer == 1 ? 'success' : 'warning'}`"
						>{{ data.item.answer == 0 ? 'RECHAZADA' : data.item.answer == 1 ? 'ACEPTADA' : 'PENDIENTE' }}</b-badge>
					</div>
				</template>

				<template #cell(sent_date)="data">
					<div
						class="text-nowrap"
					>{{ data.item.sent_date ? moment(data.item.sent_date).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>

				<template #cell(stage_end_date)="data">
					<div
						class="text-nowrap"
					>{{ data.item.stage_end_date ? moment(data.item.stage_end_date).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalInvitations"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import { onUnmounted } from "@vue/composition-api"
import invitationStoreModule from "../invitationStoreModule"
import useInvitationsList from "./useInvitationsList"
import { avatarText } from "@core/utils/filter"
import vSelect from "vue-select"
import moment from "moment"
import store from "@/store"

export default {
	components: {
		vSelect
	},
	data() {
		return {
			moment
		}
	},
	setup() {
		const INVITATION_APP_STORE_MODULE_NAME = "app-invitation"

		// REGISTER MODULE
		if (!store.hasModule(INVITATION_APP_STORE_MODULE_NAME))
			store.registerModule(
				INVITATION_APP_STORE_MODULE_NAME,
				invitationStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(INVITATION_APP_STORE_MODULE_NAME))
				store.unregisterModule(INVITATION_APP_STORE_MODULE_NAME)
		})

		const {
			fetchInvitations,
			tableColumns,
			perPage,
			currentPage,
			totalInvitations,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvitationListTable,
			refetchData
		} = useInvitationsList()

		return {
			fetchInvitations,
			tableColumns,
			perPage,
			currentPage,
			totalInvitations,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvitationListTable,
			refetchData,

			// ACTIONS
			avatarText
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>